<template>
    <v-card 
        width="100%" 
        height="100%" 
        color="bg-primary"
        class="d-flex align-center justify-center ma-0 pa-0"
        dark>
        <v-container class="ma-0 pa-0">
            <v-row>
                <v-col cols="12" md="6" class="d-flex align-center justify-center">
                    <v-img 
                        eager
                        src="https://blitzittechimages.blob.core.windows.net/blitzit/BlitzItWebFullLogo.png"
                        max-width="350"
                        class="mb-4 mb-md-0" />
                </v-col>
                <v-col cols="12" md="6" class="d-flex align-center justify-center">
                    <v-tabs-items v-model="stage" class="transparent" style="width: 100%;" touchless>
                        <v-tab-item>
                            <div class="text-center">
                                <p>What's the name of your company?</p>
                                <div class="d-flex align-center justify-center mx-auto" style="width: 400px;">
                                    <v-text-field
                                        @change="companyNameChecked = false"
                                        class="my-auto"
                                        filled
                                        hide-details
                                        label="Company Name"
                                        required
                                        rounded
                                        solo
                                        v-model="newItem.companyName" />

                                    <v-slide-x-transition>
                                        <BT-Btn
                                            v-if="newItem.companyName != null"
                                            buttonClass="bg-primary mx-1 my-auto"
                                            dark
                                            icon="mdi-arrow-right"
                                            inline
                                            large
                                            @click="selectCompanyName" />
                                    </v-slide-x-transition>
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="mx-auto" style="width: 450px;">
                                <div class="text-center">
                                    <p class="mx-4">
                                        <span>
                                            <BT-Btn
                                                buttonClass="bg-primary mx-1"
                                                dark
                                                icon="mdi-arrow-left"
                                                inline
                                                large
                                                @click="stage -= 1" />
                                        </span>
                                        <span>What kind of subscription are you wanting to use?</span>
                                    </p>
                                </div>
                                <BT-List-Endless
                                    @change="selectSubscription"
                                    :canDeselect="false"
                                    :canSearch="false"
                                    :dense="false"
                                    maxHeight="50vh"
                                    minHeight="20vh"
                                    itemText="subscriptionName"
                                    itemValue="id"
                                    navigation="subscription-plans"
                                    :onFilter="l => l.filter(x => x.isSellable)"
                                    transparent
                                    v-model="newItem.subscriptionCode">
                                    <template v-slot:listItem="{ item, select }">
                                        <v-list-item v-if="item != null" @click="select" dark>
                                            <v-list-item-icon>
                                                <v-icon class="my-auto" large>mdi-account-circle</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.subscriptionName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.subscriptionFee | toCurrency }} / month</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action-text>
                                                <div v-if="item.orderFee > 0">+{{ item.orderFee | toCurrency }} / order</div>
                                                <div v-if="item.courierFee > 0">+{{ item.courierFee | toCurrency }} / courier</div>
                                                <div v-if="item.deliveryFee > 0">+{{ item.deliveryFee | toCurrency }} / delivery</div>
                                            </v-list-item-action-text>
                                        </v-list-item>
                                    </template>
                                </BT-List-Endless>
                                <div class="d-flex">
                                    <BT-Btn
                                        v-if="newItem.subscriptionCode != null"
                                        buttonClass="bg-primary ma-2 ml-auto"
                                        dark
                                        inline
                                        label="Next"
                                        @click="stage += 1" />
                                </div>
                            </div>
                        </v-tab-item>

                        <v-tab-item>
                            <div class="text-center">
                                <p class="mx-4">
                                    <span>
                                        <BT-Btn
                                            buttonClass="bg-primary mx-1"
                                            dark
                                            icon="mdi-arrow-left"
                                            inline
                                            @click="stage -= 1" />
                                    </span>
                                    <span>What's your email address?</span>
                                </p>
                                <div class="d-flex align-center justify-center mx-auto" style="width: 400px;">
                                    <v-text-field
                                        class="my-auto"
                                        filled
                                        hide-details
                                        label="Email Address"
                                        required
                                        rounded
                                        :rules="emailRules()"
                                        solo
                                        type="email"
                                        v-model="newItem.email" />

                                    <v-slide-x-transition>
                                        <BT-Btn
                                            v-if="newItem.email != null && validEmail(newItem.email)"
                                            buttonClass="bg-primary mx-1 my-auto"
                                            dark
                                            icon="mdi-arrow-right"
                                            inline
                                            large
                                            @click="stage += 1" />
                                    </v-slide-x-transition>
                                </div>
                            </div>
                        </v-tab-item>

                        <v-tab-item>
                            <div class="mx-auto" style="width: 450px;">
                                <p class="text-center">
                                    <span>
                                        <BT-Btn
                                            buttonClass="bg-primary mx-1"
                                            dark
                                            icon="mdi-arrow-left"
                                            inline
                                            large
                                            @click="stage -= 1" />
                                    </span>
                                    <span>And what will your login credentials be?</span>
                                </p>
                                <v-text-field
                                    class="my-4 mx-auto"
                                    filled
                                    hide-details
                                    label="Username"
                                    required
                                    rounded
                                    solo
                                    v-model="newItem.userName" />

                                <v-text-field
                                    class="my-4 mx-auto"
                                    filled
                                    label="Password"
                                    required
                                    rounded
                                    :rules="passwordRules()"
                                    solo
                                    type="password"
                                    v-model="newItem.password" />

                                <v-slide-x-transition>
                                    <v-text-field
                                        v-if="newItem.password != null"
                                        class="my-4 mx-auto"
                                        filled
                                        label="Confirm Password"
                                        required
                                        rounded
                                        :rules="confirmPasswordRules"
                                        solo
                                        type="password"
                                        v-model="confirmPassword" />
                                </v-slide-x-transition>
                                <div class="d-flex">
                                    <BT-Btn
                                        v-if="newItem.userName != null && newItem.password != null && newItem.password == confirmPassword"
                                        buttonClass="bg-primary ma-2 ml-auto"
                                        dark
                                        inline
                                        label="Next"
                                        @click="stage += 1" />
                                </div>
                            </div>
                        </v-tab-item>

                        <v-tab-item>
                            <div class="mx-auto" style="width: 450px;">
                                <div class="text-center">
                                    <p class="mx-4">
                                        <span>
                                            <BT-Btn
                                                buttonClass="bg-primary mx-1"
                                                dark
                                                icon="mdi-arrow-left"
                                                inline
                                                large
                                                @click="stage -= 1" />
                                        </span>
                                        <span>And what timezone are you in?</span>
                                    </p>
                                </div>
                                <BT-Select-List-Box
                                    @change="stage += 1"
                                    dark
                                    height="50vh"
                                    isEditing
                                    :items="timeZones"
                                    itemText="text"
                                    label="Default Timezone"
                                    :onCanSelect="x => x != null"
                                    :searchProperties="['text']"
                                    transparent
                                    v-model="newItem.defaultTimeZone"
                                    width="100%" />
                                <div class="d-flex">
                                    <BT-Btn
                                        v-if="newItem.defaultTimeZone != null"
                                        buttonClass="bg-primary ma-2 ml-auto"
                                        dark
                                        inline
                                        label="Next"
                                        @click="stage += 1" />
                                </div>
                            </div>
                        </v-tab-item>

                        <v-tab-item>
                            <div class="mx-auto" style="max-width: 650px;">
                                <p class="mx-4">
                                    <span>
                                        <BT-Btn
                                            buttonClass="bg-primary mx-1"
                                            dark
                                            icon="mdi-arrow-left"
                                            inline
                                            @click="stage -= 1" />
                                    </span>
                                    <span>Lastly, what is your address?</span>
                                </p>

                                <v-slide-x-transition hide-on-leave group>
                                    <BT-Address-Auto-Complete
                                        v-if="changeAddress"
                                        @placechanged="updateAddress"
                                        id="register-address"
                                        classname="my-4 mx-auto"
                                        key="1" />

                                    <div class="text-display-4 ma-3 d-flex align-center" key="2">
                                        {{ newItem | toLocationLine }}
                                        <v-spacer />
                                        <v-btn v-if="!changeAddress" text @click="changeAddress = true" dark>Change<v-icon right>mdi-pencil</v-icon></v-btn>
                                    </div>
                                </v-slide-x-transition>

                                <GmapMap
                                    ref="locMap"
                                    :center="center"
                                    :zoom="7"
                                        style="width: 100%; height: 45vh;"
                                        :options="{
                                            zoomControl: true,
                                            mapTypeControl: false,
                                            scaleControl: false,
                                            streetViewControl: false,
                                            rotateControl: false,
                                            fullscreenControl: false,
                                            disableDefaultUi: false }">
                                        <gmap-marker v-if="newItem != null && newItem.lat != null && newItem.lng != null" :position="{ lat: newItem.lat, lng: newItem.lng }" />
                                </GmapMap>

                                <div class="d-flex">
                                    <BT-Btn
                                        v-if="newItem.lat != null && newItem.lng != null"
                                        buttonClass="bg-primary ma-2 ml-auto"
                                        dark
                                        inline
                                        label="Next"
                                        @click="stage += 1" />
                                </div>
                            </div>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <p class="mx-4">
                                    <span>
                                        <BT-Btn
                                            buttonClass="bg-primary mx-1"
                                            dark
                                            icon="mdi-arrow-left"
                                            inline
                                            @click="stage -= 1" />
                                    </span>
                                    <span>Terms And Conditions</span>
                                </p>
                                    <Terms-And-Conditions height="50vh" />
                                </v-col>
                                <v-col cols="12">
                                    <v-checkbox v-model="newItem.agreeToTermsAndConditions" dark class="ml-3">
                                        <template v-slot:label>
                                            I Agree to these Terms and Conditions
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12">
                                    <v-slide-x-transition>
                                        <BT-Btn
                                            v-if="newItem.agreeToTermsAndConditions"
                                            buttonClass="bg-primary ma-2 mx-5"
                                            dark
                                            inline
                                            label="Register"
                                            @click="register" />
                                    </v-slide-x-transition>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item class="text-center">
                            <div class="text-h4">Welcome!</div>
                            <p>You should get an email very soon with further instructions.</p>
                        </v-tab-item>

                        <span class="error--text">{{ errorMsg }}</span>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
        <v-overlay :value="loadingMsg != null" absolute class="text-center">
            <v-progress-circular indeterminate size="64" />
            <p>{{ loadingMsg }}</p>
        </v-overlay>
    </v-card>
</template>

<script>
import moment from 'moment-timezone';
import { getGoogleMapsAPI } from 'gmap-vue';
import { getLocationLine } from '~helpers';

export default {
    name: 'Register',
    components: {
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        BTAddressAutoComplete: () => import('~components/BT-Address-Auto-Complete.vue'),
        TermsAndConditions: () => import('~visit/Terms-And-Conditions.vue')
    },
    data() {
        return {
            center: { lat: -38, lng: 144 },
            changeAddress: true,
            confirmedCompanyName: null,
            companyNameChecked: false,
            confirmPassword: null,
            confirmPasswordRules: [v => v === this.newItem.password || 'Password does not match'],
            errorMsg: null,
            loadingMsg: null,
            newItem: {},
            // passwordRules: [v => this.validPassword(v) || 'Password must be at least 10 characters long'],
            stage: 0
        }
    },
    computed: {
        timeZones() {
            return moment.tz.names().map(x => { return { text: x, id: x } });
        },
        google: getGoogleMapsAPI
    },
    destroyed() {
        this.$BlitzIt.style.undoTemporaryColor(this.$vuetify);
    },
    mounted() {
        this.newItem = {
            companyName: null,
            email: null,
            userName: null,
            password: null,
            subscriptionCode: null,
            defaultTimeZone: 'Australia/Melbourne',
            addressLineOne: null,
            streetNumber: null,
            streetName: null,
            suburb: null,
            state: null,
            postcode: null,
            lng: null,
            lat: null,
            agreeToTermsAndConditions: false
        };

        this.$BlitzIt.style.setTemporaryColor(this.$vuetify, this.$BlitzIt.style.root.cosmetics.dark['bg-primary']);
    },
    methods: {
        updateAddress(res) {
            var n = this.newItem;

            n.addressLineOne = res.addressLineOne;
            n.streetNumber = res.streetNumber;
            n.streetName = res.streetName;
            n.suburb = res.suburb;
            n.state = res.state;
            n.postcode = res.postcode;
            n.lat = res.lat;
            n.lng = res.lng;

            this.changeAddress = false;
        },
        async findCoordinates() {
            this.loadingMsg = 'Loading Coordinates';
            this.stage += 1;

            await this.$gmapApiPromiseLazy();
            var address = getLocationLine(this.newItem, true);
            var request = { address: address };
            
            var geo = new this.google.maps.Geocoder();
            
            try {
                var res = await geo.geocode(request);
                res = res.results
                var selected = null;
            
                if (res.length > 1) {
                    selected = await this.$selectItemFrom({
                        items: res,
                        itemText: 'formatted_address',
                        required: true
                    });
                }
                else if (res.length == 1) {
                    selected = res[0];
                }

                if (selected != null) {
                    this.newItem.lat = selected.geometry.location.lat();
                    this.newItem.lng = selected.geometry.location.lng();
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async register() {
            try {
                this.loadingMsg = 'Registering';
                await this.$BlitzIt.api.post('account', this.newItem);
                this.stage += 1;
            }
            catch (err) {
                console.log(this.extractErrorDescription(err));
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectCompanyName() {
            if (this.newItem.companyName != null) {
                if (this.newItem.companyName != this.confirmedCompanyName) {
                    try {
                        this.loadingMsg = 'Checking Company Name';
                        await this.$BlitzIt.api.get('public-companies', `/AccountName/${this.newItem.companyName}`, null, null);
                        this.confirmedCompanyName = null;
                    }
                    catch (err) {
                        if (this.extractErrorResponseCode(err) == 404) {
                            this.confirmedCompanyName = this.newItem.companyName;
                        }
                        else {
                            this.confirmedCompanyName = null;
                        }
                    }
                    finally {
                        this.loadingMsg = null;
                        this.companyNameChecked = true;
                    }
                }

                if (this.newItem.companyName == this.confirmedCompanyName) {
                    //proceed
                    this.errorMsg = null;
                    this.stage += 1;
                }
                else {
                    this.errorMsg = 'Please use a different company name.';
                }
            }
        },
        selectSubscription(item) {
            if (item != null) {
                this.stage += 1;
            }
        }
    } 
}
</script>